import { useRouteError } from "react-router-dom";

import styles from "./404.module.scss";

const ErrorPage: React.FC = () => {
  const error: any = useRouteError();

  return (
    <div className={styles.container}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message || "Error"}</i>
      </p>
    </div>
  );
};

export default ErrorPage;
