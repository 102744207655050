import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { RotatingLines } from "react-loader-spinner";

import Card from "../../components/card";

import { fetchQuestions, postAnswers } from "../../utils/apis";
import { Mixpanel } from "../../utils/mixpanel";

import styles from "./questions.module.scss";

import logo from "../../assets/logos/logo2.png";
import star from "../../assets/star.png";
import questionBar from "../../assets/inside-bar.png";

const calculateCompletionPercentage = (
  currentQuestion: number,
  totalQuestions: number
): string => {
  const percentage = (currentQuestion / totalQuestions) * 100;
  return `${
    parseFloat(percentage.toFixed(2)) -
    (percentage < 50 ? 0 : percentage < 90 ? 1.5 : 3)
  }%`;
};

const goalDate = new Date("2024-02-11T18:30:00-05:00");

const Questions: React.FC = () => {
  const [question, setQuestion] = useState<number>(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions[]>([]);
  const [isLoadingFinal, setIsLoadingFinal] = useState<boolean>(false);
  const [modal, setModal] = useState({ shown: false, content: "" });

  const { data, isLoading, error } = useQuery<QuestionsData, Error>({
    queryKey: ["questions"],
    queryFn: fetchQuestions,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (modal.shown) {
      // Set a timeout to call closeModal after 2 seconds
      const timeoutId = setTimeout(() => {
        closeModal();
      }, 3000);

      // Clean up the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [modal.shown]);

  const onClickHandler = async (id: string, choice: boolean) => {
    if (!data) return;

    try {
      const temp = selectedOptions;
      temp.push({ prop_id: id, choice });
      setSelectedOptions(temp);

      if (question + 1 < data.props.length) {
        setQuestion((current) => current + 1);
        setButtonDisabled(false);
        const foundItem = data.props.find((i) => i.prop_id === id);
        if (foundItem?.bonus) {
          setModal({ shown: true, content: foundItem?.bonus });
        }
      } else {
        console.log(JSON.stringify(selectedOptions));
        setIsLoadingFinal(true);
        const res = await postAnswers(selectedOptions);
        console.log("RES:" + JSON.stringify(res));
        navigate(`/congrats/${res.display_name}`, {
          state: { options: selectedOptions, response: res },
        });
        Mixpanel.identify(res.display_name);
        Mixpanel.track("Answers submitted", {
          "Answers submitted": selectedOptions,
        });
      }
    } catch (e) {
      console.log("ERROR: " + e);
    } finally {
      setIsLoadingFinal(false);
    }
  };

  const closeModal = () => {
    setModal({ shown: false, content: "" });
  };

  if (isLoading || isLoadingFinal) {
    return (
      <div className={styles.loading}>
        <RotatingLines
          strokeColor="rgb(250, 43, 82)"
          strokeWidth="4"
          ariaLabel="rotating-lines-loading"
          width="80"
        />
      </div>
    );
  }

  if (error) {
    console.error(error);
    return <div>SOMETHING WENT WRONG...</div>;
  }

  return (
    <div className={styles.container}>
      <Link to="/">
        <img src={logo} alt="Logo" className={styles.logo} />
      </Link>
      <div className={styles.barContainer}>
        <img src={questionBar} alt="" className={styles.bar} />
        <div
          className={styles.barInner}
          style={{
            width: `${calculateCompletionPercentage(
              question + 1,
              data?.props?.length || 0
            )}`,
          }}
        />
        <div className={styles.questionNumber}>
          QUESTION {question + 1} / {data?.props?.length}
        </div>
      </div>
      <div className={styles.center}>
        {data?.props?.map((item, index) => (
          <Card
            buttonDisabled={buttonDisabled}
            setButtonDisabled={setButtonDisabled}
            item={item}
            zIndex={data.props.length - index}
            key={item.prop_id}
            onClick={onClickHandler}
          />
        ))}
      </div>
      {modal.shown ? (
        <div className={styles.modal} onClick={closeModal}>
          <div className={styles.inner}>
            <img alt="Star" src={star} />
            <div className={styles.content}>
              <h3>{modal.content}</h3>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Questions;
