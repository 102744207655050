import axios from "axios";

export const fetchQuestions = async () => {
  const { data } = await axios.get("https://api.superpicksunday.com/props");

  return data;
};

export const postAnswers = async (selectedOptions: SelectedOptions[]) => {
  const { data } = await axios.post("https://api.superpicksunday.com/enter", { picks: selectedOptions });

  return data;
};

export const postEmail = async (emailData: EmailData) => {
  const { data } = await axios.post("https://api.superpicksunday.com/email", { ...emailData });

  return data;
};

export const fetchLeaderboard = async () => {
  const { data } = await axios.get("https://api.superpicksunday.com/leaders");

  return data;
};

export const fetchTeamData = async (display_name: string) => {
  const { data } = await axios.get(`https://api.superpicksunday.com/team/${display_name}`);

  return data;
};
