import { useState } from "react";
import { Link } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { fetchQuestions } from "../../utils/apis";

import styles from "./root.module.scss";

import bg1 from "../../assets/backgrounds/1.png";
import bg2 from "../../assets/backgrounds/2.png";
import bg3 from "../../assets/backgrounds/3.jpg";
import bg4 from "../../assets/backgrounds/4.jpg";

import bg1Big from "../../assets/backgrounds/1-big.png";
import bg2Big from "../../assets/backgrounds/2-big.png";
import bg3Big from "../../assets/backgrounds/3-big.png";
import bg4Big from "../../assets/backgrounds/4-big.png";

import logo1 from "../../assets/logos/logo1.png";
import logo2 from "../../assets/logos/logo2.png";
import leaderboard from "../../assets/buttons/leaderboard.png";

import { ReactComponent as Button1 } from "../../assets/buttons/button1.svg";
import { ReactComponent as Button2 } from "../../assets/buttons/button2.svg";
import { ReactComponent as CloseButton } from "../../assets/modal/close.svg";

const allBackgrounds = [bg1, bg2, bg3, bg4];
const allBackgroundsBig = [bg1Big, bg2Big, bg3Big, bg4Big];
const randomNumber = Math.floor(Math.random() * allBackgrounds.length);
const randomImage = allBackgrounds[randomNumber];
const randomImageBig = allBackgroundsBig[randomNumber];
const selectedLogo = randomNumber === 3 ? logo1 : logo2;

const Root: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  useQuery({
    queryKey: ["questions"],
    queryFn: fetchQuestions,
  });

  const onHowToPlayClickHandler = () => {
    setShowModal(true);
  };

  return (
    <div className={styles.container}>
      <picture>
        <source
          media="(max-width: 992px)"
          srcSet={randomImage}
          className={styles.background}
        />
        <img
          src={randomImageBig}
          className={styles.background}
          alt="Background"
        />
      </picture>
      <img src={selectedLogo} className={styles.logo} alt="Logo" />
      <div className={styles.bottom}>
        <Link to="/questions">
          <div className={styles.button}>
            <Button1 />
            <h2>PLAY</h2>
          </div>
        </Link>
        <div
          className={`${styles.button} ${styles.button2}`}
          onClick={onHowToPlayClickHandler}
        >
          <Button2 />
          <h2 className={styles.howToPlay}>HOW TO PLAY</h2>
        </div>
        <Link to="/leaderboard">
          <img alt="" className={styles.button} src={leaderboard} />
        </Link>
      </div>
      {showModal ? (
        <>
          <div className={styles.overlay} onClick={() => setShowModal(false)} />
          <div className={styles.modal}>
            <CloseButton
              onClick={() => setShowModal(false)}
              className={styles.close}
            />
            <div className={styles.inner}>
              <h2 className={styles.howToPlay}>HOW TO PLAY</h2>
              <div className={styles.paragraphs}>
                <p>
                  1. YOU'LL SEE A SERIES OF QUESTIONS ABOUT THE SUPER BOWL
                  DISPLAYED ON TILES
                </p>
                <p>
                  2. SIMPLY SWIPE RIGHT TO AGREE OR LEFT TO DISAGREE WITH EACH
                  PREDICTION
                </p>
                <p>
                  3. MAKE YOUR CHOICES BEFORE THE GAME STARTS. TRACK YOUR PICKS
                  AND SEE HOW YOU STACK UP ON A GLOBAL LEADERBOARD
                </p>
              </div>
              <div
                className={styles.button}
                onClick={() => setShowModal(false)}
              >
                <Button1 />
                <h3>CONTINUE</h3>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Root;
