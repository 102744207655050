import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_API!, {
  track_pageview: true,
  persistence: "localStorage",
});

// const env_check: boolean = process.env.NODE_ENV === "production";
const env_check: boolean = true;

const actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  track: (name: string, props: Record<string, unknown>) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: Record<string, string>) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
