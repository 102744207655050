import TinderCard from "react-tinder-card";

import styles from "./card.module.scss";

import { ReactComponent as SelectButton } from "../../assets/selectButton.svg";
import { useRef, useState } from "react";

type Direction = "left" | "right" | "up" | "down";
interface API {
  swipe(dir?: Direction): Promise<void>;
  restoreCard(): Promise<void>;
}
interface Props {
  item: QuestionData;
  onClick: (prop_id: string, choice: boolean) => void;
  zIndex: number;
  buttonDisabled: boolean;
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const renderSingleChoice = (inputString: string): string => {
  const upperCaseString = inputString.toUpperCase();

  if (upperCaseString.includes("OVER") || upperCaseString.includes("UNDER")) {
    return inputString.replace(/(OVER|UNDER)/i, "$1\n").toUpperCase();
  }

  if (upperCaseString.includes("/") && !upperCaseString.includes(" / ")) {
    return inputString.split("/").join(" / ").toUpperCase();
  }

  return inputString.toUpperCase();
};

const Card: React.FC<Props> = ({
  item,
  onClick,
  zIndex,
  buttonDisabled,
  setButtonDisabled,
}) => {
  const [isShown, setIsShown] = useState<boolean>(true);

  const cardRef = useRef<API>(null);

  const onSwipeCompleted = (direction: Direction) => {
    onItemClick(direction !== "left");
  };

  const onItemClick = (choice: boolean) => {
    console.log("item clicked: " + item);
    onClick(item.prop_id || "no_id", choice);
    setIsShown(false);
  };

  const renderTitle = () => {
    return <h2>{item.description?.toUpperCase()}</h2>;
  };

  const handleButtonClick = (direction: "left" | "right") => {
    if (buttonDisabled) return;
    setButtonDisabled(true);
    cardRef.current?.swipe(direction);
  };

  const renderChoices = () => {
    return (
      <div className={styles.row}>
        <div
          className={styles.button}
          onClick={() => handleButtonClick("left")}
        >
          <SelectButton className={styles.selectButton} />
          <h4>{renderSingleChoice(item.choiceF)}</h4>
        </div>
        <div
          className={styles.button}
          onClick={() => handleButtonClick("right")}
        >
          <SelectButton className={styles.selectButton} />
          <h4>{renderSingleChoice(item.choiceT)}</h4>
        </div>
      </div>
    );
  };

  if (!isShown) return null;

  return (
    <div className={styles.mainContainer} style={{ zIndex }}>
      <TinderCard
        onCardLeftScreen={onSwipeCompleted}
        onSwipeRequirementFulfilled={() => console.log("FULFILL")}
        preventSwipe={["up", "down"]}
        className={styles.container}
        ref={cardRef}
      >
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            src={item.image}
            draggable="false"
            alt=""
          />
        </div>
        <div className={styles.titleContainer}>{renderTitle()}</div>
      </TinderCard>
      {renderChoices()}
    </div>
  );
};

export default Card;
